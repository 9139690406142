import React, { Component } from 'react'
import './layout.css'
import NativeFooter from './carbonads/nativefooter';

class Layout extends Component {



  render() {
    return (
      <div>
        <>
          <div>{this.props.children}</div>
          <NativeFooter />
          {/* <iframe data-id="reactgo.com_1000x100_anchor_responsive_DFP" frameBorder="0" scrolling="no" marginHeight="0"
            marginWidth="0" topmargin="0" leftmargin="0" width="1" height="1"></iframe>
          <script dangerouslySetInnerHTML={{
            __html: `(function () {
          var size='970x90|320x100',
            adunit = 'reactgo.com_1000x100_anchor_responsive_DFP',
            childNetworkId = '21832033504',
            xmlhttp = new XMLHttpRequest();xmlhttp.onreadystatechange = function(){if(xmlhttp.readyState==4 && xmlhttp.status==200){var es = document.querySelectorAll("[data-id='"+adunit+"']");var e = Array.from(es).filter(function(e) {return !e.hasAttribute("data-rendered")});if(e.length > 0){e.forEach(function (el) { var iframe = el.contentWindow.document; iframe.open(); iframe.write(xmlhttp.responseText); iframe.close(); el.setAttribute('data-rendered', true) })}}};var child=childNetworkId.trim()?','+childNetworkId.trim():'';xmlhttp.open("GET", 'https://pubads.g.doubleclick.net/gampad/adx?iu=/147246189'+child+'/'+adunit+'&sz='+encodeURI(size)+'&t=Placement_type%3Dserving&'+Date.now(), true);xmlhttp.send();})();
           `}}
          /> */}

        </>
      </div>
    )
  }

}

export default Layout;
