import React from 'react'
import { Link } from 'gatsby'
import './header.css'
import GithubIcon from 'react-icons/lib/go/mark-github'
import HeaderIMg from '../img/header-bg-img.jpeg';
import kofi from './mypage/kofi.png'
import siteimage from '../img/download.png';

class Header extends React.Component {
  state = {
    active: false
  }

  openUp = () => {
    this.setState({
      active: !this.state.active,
    })
  }


  render() {
    const { siteTitle } = this.props;
    const activeColor = { backgroundColor: '#f5f5f5' }
    return (
      <div className="main-nav" style={{ position: this.props.courseurl ? "fixed" : "relative" }}>
        {/* <button onClick={this.openUp} className="navbtn">
          {this.state.active ? 'Show' : 'Hide'}
        </button> */}
        {/* <div className="pop-head">
          <p><span className="highlight"> 🚀 &nbsp;Pre-order 🚀 </span>
            <a href="https://gumroad.com/l/frontend-interview-qna" target="_blank">
              Frontend Developer Interview Questions <span className="end">for $10 off!</span> 👀 </a>
          </p>
        </div> */}
        <div className="header" >
          {!this.state.active && (
            <Link to="/" className={`logostyle boxlogo`}>
              {siteTitle}
            </Link>
          )}
          <nav
            className={this.state.active ? 'nav' : 'nav active'}
            aria-hidden={this.state.active ? true : false}
          >
            <Link
              to={'/tutorials/angular/'}
              activeStyle={activeColor}
            >
              Angular
            </Link>
            <Link
              to={'/tutorials/react/'}
              activeStyle={activeColor}
            >
              React
            </Link>
            <Link
              to={'/tutorials/vuejs/'}
              activeStyle={activeColor}
            >
              Vue.js
            </Link>
            <Link
              to={'/reactrouter/tutorial/'}
              activeStyle={activeColor}
            >
              Reactrouter
            </Link>
            <Link
              to={'/tutorials/algorithms/'}
              activeStyle={activeColor}
            >
              Algorithms
            </Link>
            <Link
              to={'/tutorials/graphql/'}
              activeStyle={activeColor}
            >
              GraphQL
            </Link>
          </nav>
          {/* <a href="https://saigowtham.typeform.com/to/DVLeoo"
            target="_blank"
            rel="noopener noreferrer"
            className="request-tut">Request a tutorial</a> */}
          <a
            className="github"
            href="https://github.com/saigowthamr/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'black' }}
            title="GitHub"
          >
            <GithubIcon style={{ verticalAlign: `text-top` }} size={'23px'} />
          </a>
        </div>
      </div>
    )
  }
}

export default Header
