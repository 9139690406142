import React, { Component } from 'react';


class NativeFooter extends Component {

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            const script = document.createElement("script");
            script.src = "//m.servedby-buysellads.com/monetization.js";
            script.id = "by";
            script.async = true;
            this.div.appendChild(script);

            script.onload = () => {
                this.adLoader();
            }
        }

        // window.addEventListener('load', this.adLoader)
    }


    adLoader = () => {
        (function () {
            let _bsa = window._bsa;

            if (typeof _bsa !== 'undefined' && _bsa) {
                _bsa.init('custom', 'CE7DV2JY', 'placement:reactgocom', {
                    target: '#native-fixed-js',
                    template: `
<div style="background-color: ##backgroundColor##" class="native-fixed">
  <a style="color: ##textColor##" class="native-link" href="##link##">
    <div class="native-sponsor" style="background-color: ##textColor##; color: ##backgroundColor##">Sponsor</div>
    <div class="native-text">##company## — ##description##</div>
  </a>
</div>
    `
                });
            }
        })()
    }

    componentWillUnmount() {
        let s1 = document.getElementById('by');
        let s2 = document.getElementById('_bsa_srv-CE7DV2JY_0');
        // s1 && document.body.removeChild(s1);
        s2 && document.head.removeChild(s2);

        s1 && this.div.removeChild(s1);
        // window.removeEventListener('load', this.adLoader);
    }

    render() {
        return (
            <div ref={el => this.div = el}>
                <div id="native-fixed-js"></div>
            </div>
        )
    }
}

export default NativeFooter;